<template>
    <div class="container">
        <div class="toolbar">
            <div class="filters-row">
                <div class="filters-row__filter">
                    <SchoolDropDown @selected-school-id-updated="setSelectedSchool" />
                </div>
            </div>
        </div>
        <div class="filters">
            <div class="filters-row">
                <div class="filters-row__filter">
                    <label>Search for a Tag</label>
                </div>
                <div class="filters-row__filter">
                    <div
                        v-for="tag in selectedTagsRef"
                        :key="tag.id"
                        class="selected-tags"
                        @click="removeSelectedTag(tag.id)"
                    >
                        <span
                            >{{ tag.name }}
                            {{ tag.isPublic ? '(Public)' : `(${tag.schoolRef.name})` }}</span
                        ><span class="remove-tag">x</span>
                    </div>
                </div>
                <div class="filters-row__filter">
                    <button
                        v-if="hideTagDropDown && selectedTags.length"
                        class="add-filters"
                        @click="hideTagDropDown = false"
                    >
                        <b>+</b>
                    </button>
                    <div v-if="!hideTagDropDown || !selectedTags.length" class="dd__tags">
                        <DropDownSelect
                            :items="tagsList.list"
                            displayKey="name"
                            valueKey="id"
                            v-model="selectedTags"
                            v-model:itemsRef="selectedTagsRef"
                            :hasNext="tagsList.pagination.hasNextPage"
                            @load-more="tagsList.addNextPage()"
                            @search-term="onSearchTags"
                            @update:modelValue="hideTagDropDown = true"
                            searchable
                            multi
                            hidden
                            closeOnSelect
                            :disabled="disableAllFilters || disableAllEventActions"
                        />
                    </div>
                </div>

                <div class="filters-row__filter">
                    <label>Phase</label>
                    <div class="dd__phases">
                        <DropDownSelect
                            :items="phases"
                            displayKey="name"
                            valueKey="id"
                            v-model="selectedPhase"
                            v-model:itemsRef="selectedPhaseRef"
                            local
                            :disabled="disableAllFilters || disableAllEventActions"
                        />
                    </div>
                </div>
                <div class="filters-row__filter">
                    <label>Phone Number</label>
                    <div class="dd__valid-phone">
                        <DropDownSelect
                            :items="phoneOpts"
                            v-model="selectedValidPhone"
                            v-model:itemsRef="selectedValidPhoneRef"
                            local
                            :disabled="disableAllFilters || disableAllEventActions"
                        />
                    </div>
                </div>
                <div class="filters-row__filter">
                    <label>Opted-in:</label>
                    <div class="dd__opt-in">
                        <DropDownSelect
                            :items="optedIn"
                            v-model="selectedOptIn"
                            v-model:itemsRef="selectedOptInRef"
                            local
                            :disabled="disableAllFilters || disableAllEventActions"
                        />
                    </div>
                </div>
                <div class="filters-row__filter">
                    <label>First Time Engager:</label>
                    <div class="dd__received-messages">
                        <DropDownSelect
                            :items="yesNo"
                            v-model="firstTimeEngager"
                            v-model:itemsRef="firstTimeEngagerRef"
                            local
                            :disabled="disableAllFilters || disableAllEventActions"
                        />
                    </div>
                </div>
                <div class="filters-row__filter">
                    <label>Risk Driver</label>
                    <div class="dd__risk-driver">
                        <DropDownSelect
                            :items="riskDrivers"
                            displayKey="name"
                            valueKey="id"
                            v-model="selectedRiskDriver"
                            v-model:itemsRef="selectedRiskDriverRef"
                            :disabled="disableAllFilters || disableAllEventActions"
                        />
                    </div>
                </div>

                <div class="filters-row__filter">
                    <label>Risk Level</label>
                    <div class="dd__risk-level">
                        <DropDownSelect
                            :items="riskLevels"
                            displayKey="name"
                            valueKey="id"
                            v-model="selectedRiskLevel"
                            v-model:itemsRef="selectedRiskLevelRef"
                            :disabled="disableAllFilters || disableAllEventActions"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="students-row">
            <div class="table-toolbar">
                <div class="count">
                    <transition name="selected-students">
                        <div v-if="selectedStudents.length !== 0" class="selected-students">
                            {{ totalStudentsSelected }} Students Selected
                        </div>
                    </transition>
                </div>

                <ButtonDropDown
                    :disabled="!selectedStudents.length"
                    @btn-toggle="onActionDdToggle"
                    :actions="actionDdButtons"
                >
                    Actions
                    <span>
                        <svg
                            width="10px"
                            height="10px"
                            class="dd-icon"
                            :class="{
                                'dd-icon__enabled': selectedStudents.length >= 1,
                                'dd-icon__open': actionDropBtnOpened
                            }"
                            viewBox="0 0 292.362 292.362"
                        >
                            <use xlink:href="#caret-down" />
                        </svg>
                    </span>
                </ButtonDropDown>
            </div>

            <ComplexTable
                :busy="this.students.refreshing"
                :columns="[
                    'checkBox',
                    'school',
                    'displayName',
                    'email',
                    'phoneNumber',
                    'riskPercentile',
                    'riskScore',
                    'phase'
                ]"
                :rows="students.list"
                stickyHeader
            >
                <template v-slot:col-checkBox>
                    <span class="cell-checkbox">
                        <BaseCheckbox
                            :isMedium="true"
                            :watchChecked="true"
                            :checked="allStudentsSelected"
                            :modelValue="allStudentsSelected"
                            @update:modelValue="toggleAllStudents"
                            :alignCenter="true"
                            :disabled="disableAllEventActions || students.list.length <= 0"
                        />
                    </span>
                </template>
                <template v-slot:col-displayName>
                    <span>
                        Name
                    </span>
                </template>
                <template v-slot:col-phoneNumber>
                    <span>
                        Phone Number
                    </span>
                </template>
                <template v-slot:col-riskPercentile>
                    <span>
                        Risk Percentile
                    </span>
                </template>
                <template v-slot:col-riskScore>
                    <span>
                        Risk Score
                    </span>
                </template>

                <template v-slot:cell-checkBox="{ row }">
                    <span class="cell-checkbox">
                        <span class="cell-checkbox">
                            <BaseCheckbox
                                :modelValue="studentIsSelected(row)"
                                @update:modelValue="toggleStudentSelection(row)"
                            />
                        </span>
                    </span>
                </template>
                <template v-slot:table-row-loading>
                    <LoadingSpinner v-if="students.refreshing" />
                </template>
                <template v-slot:table-row-empty>
                    <span>No Available Students</span>
                </template>
                <template v-slot:cell-school="{ row, click }">
                    <div class="table-cell school" v-tooltip="row.schoolRef.name">
                        {{ row.schoolRef.name }}
                    </div>
                </template>
                <template v-slot:cell-riskPercentile="{ row, click }">
                    <div class="table-cell school" v-tooltip="row.overallRiskPercentile">
                        {{ row.overallRiskPercentile }}
                    </div>
                </template>
                <template v-slot:cell-riskScore="{ row, click }">
                    <div class="table-cell school" v-tooltip="row.overallRiskPoints">
                        {{ row.overallRiskPoints }}
                    </div>
                </template>
                <template v-slot:cell-phase="{ row, click }">
                    <div class="table-cell phase" v-tooltip="row.phase">
                        {{ row.phase == 'NO_PHASE' ? 'No Phase' : row.phase }}
                    </div>
                </template>
                <template v-slot:table-row-footer>
                    <Paginator
                        v-show="students.pagination.next"
                        :loading="students.loadingNextPage"
                        :nextPage="students.pagination.next != null"
                        @next-page="onGetNextStudents"
                    />
                </template>
            </ComplexTable>
        </div>
        <ModalGeneral>
            <template v-slot:modal-title>
                <span class="modal-header-title">
                    Phase Update in Progress
                </span>
            </template>
            <template v-slot:confirmation-message>
                <span class="student-count-message">
                    This operation may take a while, we will notify you as soon as your changes are
                    made.</span
                >
            </template></ModalGeneral
        >
        <ModalStaffStudentPhase @success="onUpdated" />
        <ModalUploadConfirmation>
            <template v-slot:confirmation-message>
                <span class="student-count-message"> {{ updatedCount }} student(s) updated</span>
            </template>
        </ModalUploadConfirmation>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import DropDownSelect from '@/components-deprecated/inputs/DropDownSelect';
import CollectionManager from '@/services/collectionManager';
import Tag from '@/services/tags';

import Student from '@/services/students';
import ComplexTable from '@/components-deprecated/tables/ComplexTable';
import { BaseCheckbox } from '@edsights/ui-core';
import ButtonDropDown from '@/components-deprecated/ButtonDropDown';
import ModalStaffStudentPhase from '@/components-deprecated/staff/ModalStaffStudentPhase';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import ModalUploadConfirmation from '@/components-deprecated/admin/ModalUploadConfirmation';
import Paginator from '@/components-deprecated/Paginator';
import ModalGeneral from '@/components-deprecated/admin/ModalGeneral';
import SchoolDropDown from '@/components-deprecated/inputs/SchoolDropDown';

const PHASES = [
    { name: 'Adjustment', id: 'ADJUSTMENT' },
    { name: 'Growth', id: 'GROWTH' },
    { name: 'Preparing to leave', id: 'PREPARING_TO_LEAVE' },
    { name: 'Announcements', id: 'ANNOUNCEMENTS' },
    { name: 'No Phase', id: 'NO_PHASE' }
];
const RISK_LEVEL = [
    { name: 'High', id: 'HIGH_RISK' },
    { name: 'Medium', id: 'MEDIUM_RISK' },
    { name: 'Low', id: 'LOW_RISK' }
];
const RISK_DRIVER = [
    { name: 'Overall', id: 'overall_risk_level', id2: 'OVERALL' },
    { name: 'Academic', id: 'academic_risk_level', id2: 'ACADEMIC' },
    { name: 'Financial', id: 'financial_risk_level', id2: 'FINANCIAL' },
    { name: 'Engagement', id: 'engagement_risk_level', id2: 'ENGAGEMENT' },
    { name: 'Wellness', id: 'wellness_risk_level', id2: 'WELLNESS' }
];
const YES_NO = [
    { key: 'Yes', value: 'true' },
    { key: 'No', value: 'false' }
];
const VALID_PHONE = [
    { key: 'Invalid', value: 'true' },
    { key: 'Valid', value: 'false' }
];
const OPTED_IN = [
    { key: 'Yes', value: 'false' },
    { key: 'No', value: 'true' }
];

export default {
    name: 'StaffStudentsList',
    components: {
        SchoolDropDown,
        DropDownSelect,
        ComplexTable,
        BaseCheckbox,
        ButtonDropDown,
        ModalStaffStudentPhase,
        LoadingSpinner,
        ModalUploadConfirmation,
        Paginator,
        ModalGeneral
    },

    data() {
        return {
            // Claudia would like a button to indicate that users can add more tags,
            // so we are hiding the tag dd until they click the button to add more
            hideTagDropDown: false,
            disableAllFilters: false,
            updatedCount: 0,
            random: null,
            totalStudentsSelected: this.selectedStudentsLength,
            actionDdButtons: [{ name: 'Move Phase', onclick: this.onMovePhase }],
            actionDropBtnOpened: false,
            invalidPhoneOpts: VALID_PHONE,
            yesNo: YES_NO,
            optedIn: OPTED_IN,
            phoneOpts: VALID_PHONE,
            selectedStudents: [],
            allStudentsSelected: false,
            allFromServerSelected: false,
            phases: PHASES,
            riskLevels: RISK_LEVEL,
            riskDrivers: RISK_DRIVER,
            selectedOptIn: null,
            firstTimeEngager: null,
            selectedValidPhone: null,
            selectedRiskLevel: null,
            selectedRiskDriver: null,
            selectedPhase: null,
            //object versions of selected items
            // used if a selected item is from a paginated result
            // also passed to the popup to show the list of filters
            selectedOptInRef: null,
            firstTimeEngagerRef: null,
            selectedValidPhoneRef: null,
            selectedRiskLevelRef: null,
            selectedRiskDriverRef: null,
            selectedPhaseRef: null,
            selectedSchoolRef: null,
            selectedTagsRef: [],
            selectedinterventionRef: null,
            selectedintervention: null,
            school: null,
            schoolFilters: {},
            selectedSchool: null,
            selectedTags: [],
            tagsList: CollectionManager.create({
                ModelClass: Tag,
                filters: {
                    for_school: null,
                    is_public: true
                }
            }),
            students: CollectionManager.create({
                ModelClass: Student,
                filters: {
                    ...this.studentFilters
                }
            })
        };
    },
    created() {
        this.tagsList.refresh();
        this.students.refresh();
    },
    mounted() {},

    unmounted() {},
    watch: {
        selectedStudentsLength(val) {
            // watcher that checks or unchecks the select all checkbox
            if (
                (this.totalStudentsSelected !== this.students.pagination.totalCount) |
                    (val < this.students.list.length) &&
                this.students.list.length > 0
            ) {
                this.allStudentsSelected = false;
                this.totalStudentsSelected = val;
            } else {
                this.allStudentsSelected = true;
            }
        },
        studentFilters: {
            // watcher that watches the student filters and resends request
            // when they are changed, it also unchecks the selected students
            deep: true,
            async handler() {
                this.toggleAllStudents(false);
                this.allStudentsSelected = false;
                this.students.filters = this.studentFilters;
                this.students.pagination.page = 1;
                await this.students.refresh();
            }
        },
        selectedRiskLevel(val) {
            //set the risk driver to a default if none is selected or null if level is removed
            if (!val) {
                this.selectedRiskDriver = null;
                this.selectedRiskDriverRef = null;
            }
            if (val && !this.selectedRiskDriver) {
                this.selectedRiskDriver = 'overall_risk_level';
                this.selectedRiskDriverRef = this.riskDrivers.find(
                    driver => driver.id == this.selectedRiskDriver
                );
            }
        },
        selectedRiskDriver(val) {
            //set the risk level to a default if none is selected or null if driver is removed
            if (!val) {
                this.selectedRiskLevel = null;
                this.selectedRislLevelRef = null;
            }
            if (val && !this.selectedRiskLevel) {
                this.selectedRiskLevel = 'HIGH_RISK';
                this.selectedRiskLevelRef = this.riskLevels.find(
                    level => level.id == this.selectedRiskLevel
                );
            }
        },
        async selectedSchool() {
            if (!this.selectedSchool) {
                // only show public tags
                this.tagsList.filters = {
                    ...this.tagsList.filters,
                    for_school: null,
                    is_public: true,
                    page: 1
                };
            } else {
                this.tagsList.filters = {
                    ...this.tagsList.filters,
                    for_school: this.selectedSchool,
                    is_public: null,

                    page: 1
                };
            }
            await this.tagsList.refresh();
        }
    },
    methods: {
        ...mapActions(['updateBackgroundOperation']),
        removeSelectedTag(tagId) {
            let index = this.selectedTags.findIndex(tag => tag == tagId);
            if (index != -1) {
                this.selectedTags.splice(index, 1);
                this.selectedTagsRef.splice(index, 1);
            }
        },
        async onGetNextStudents() {
            this.students.addNextPage();
        },
        async onSearchTags(val) {
            this.tagsList.filters.search = val;
            await this.tagsList.refresh();
        },
        resetFilters() {
            // optional helper method to reset the filters
            this.selectedSchool = null;
            this.selectedSchoolRef = null;
            this.selectedintervention = null;
            this.selectedinterventionRef = null;
            this.selectedOptIn = null;
            this.selectedOptInRef = null;
            this.selectedTags = [];
            this.selectedTagsRef = [];
            this.selectedPhase = null;
            this.selectedPhaseRef = null;
            this.selectedRiskDriver = null;
            this.selectedRiskDriverRef = null;
            this.selectedRiskLevel = null;
            this.selectedRiskLevelRef = null;
            this.selectedValidPhone = null;
            this.selectedValidPhoneRef = null;
            this.firstTimeEngager = null;
            this.firstTimeEngagerRef = null;
        },
        onUpdated(val) {
            if (!val.isBackground) {
                this.$modal.show('modal-upload-confirmation');

                this.updatedCount = val.results[0].value.length;

                this.students.refresh();
            } else {
                this.$modal.show('modal-general');
                this.updateBackgroundOperation(val.backgroundOp);
            }
            this.selectedStudents = [];
            this.allStudentsSelected = false;
            this.toggleAllStudents(false);
        },
        onMovePhase() {
            // builds out user friendly objects from selected filters for modal
            this.$modal.show('modal-change-student-phase', {
                allStudents: this.allStudentsSelected,
                count: this.selectedStudents.length,
                totalCount: this.students.pagination.totalCount,
                students: this.allStudentsSelected ? [] : this.selectedStudents,
                phaseOptions: this.phases,
                filters: {
                    tags: this.selectedTags.length ? this.selectedTagsRef.map(t => t.name) : null,
                    schools: this.selectedSchool ? this.selectedSchoolRef.name : null,
                    intervention: this.selectedintervention
                        ? this.selectedinterventionRef.text
                        : null,
                    optedOutOfSms: this.selectedOptInRef ? this.selectedOptInRef.key : null,
                    invalidPhoneNumber:
                        this.selectedValidPhone != null ? this.selectedValidPhoneRef.key : null,
                    firstTimeEngager: this.firstTimeEngager ? this.firstTimeEngagerRef.key : null,
                    phase: this.selectedPhaseRef ? this.selectedPhaseRef.name : null,
                    riskDrivers: this.selectedRiskDriver ? this.selectedRiskDriverRef.name : null,
                    riskLevels: this.selectedRiskLevel ? this.selectedRiskLevelRef.name : null
                },
                queryParams: this.studentFilters
            });
        },

        toggleAllStudents(val = !this.allStudentsSelected) {
            this.allStudentsSelected = val;
            if (this.allStudentsSelected) {
                // order here is important watcher will update to total in list first
                this.selectedStudents = this.students.list;
                // after that we can set the total cout to the total of all results
                this.totalStudentsSelected = this.students.pagination.totalCount;
            } else {
                this.selectedStudents = [];
                this.totalStudentsSelected = 0;
            }
        },
        onActionDdToggle(val) {
            this.actionDropBtnOpened = val;
        },
        setSelectedSchool(schoolId, schoolRef) {
            this.selectedSchool = schoolId;
            this.selectedSchoolRef = schoolRef;
        },
        studentIsSelected(row) {
            return this.selectedStudents.find(student => student.id === row.id) ? true : false;
        },
        toggleStudentSelection(row) {
            if (this.studentIsSelected(row)) {
                this.selectedStudents = this.selectedStudents.filter(
                    student => student.id !== row.id
                );
            } else {
                this.selectedStudents.push(row);
            }
        }
    },
    computed: {
        disableAllEventActions() {
            // helper to disable all clickable items e.g filters,buttons,checkboxes while refreshing
            return this.students.refreshing;
        },
        selectedStudentsLength() {
            return this.selectedStudents.length;
        },

        riskFilter() {
            if (!this.selectedRiskLevel && !this.selectedRiskDriver) {
                return null;
            }

            return { [this.selectedRiskDriver]: this.selectedRiskLevel };
        },

        studentFilters() {
            //reactive getter that is used to send filter request to students endpoint
            let filters = {
                schools: this.selectedSchool,
                tagIds: this.selectedTags.length ? this.selectedTags.join(',') : null,
                phase: this.selectedPhase,
                intervention: this.selectedintervention,
                optedOutOfSms: this.selectedOptIn,
                invalidPhoneNumber: this.selectedValidPhone,
                firstTimeEngager: this.firstTimeEngager
            };
            if (this.riskFilter) {
                filters = { ...filters, ...this.riskFilter };
            }
            return filters;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
:deep(.dropdown) {
    // manually setting input style here

    .dropdown-input-container {
        width: 100%;
        border-radius: 30px;
        border: 1px solid $edsights-blue;
        &.disabled {
            border: 1px solid gray;
        }
    }
    .selected-items.multi {
        .selected-items__item {
            margin-top: 0.2rem;
            background-color: $edsights-blue;
            height: 100%;
        }
    }
}
.student-count-message {
    font-size: 1.3rem;
    padding-bottom: 5rem;
}

.scroll-container {
    overflow: overlay;
}
.selected-tags {
    display: flex;
    justify-content: space-between;
    height: 3rem;
    border-radius: 30px;
    border: 1px solid $edsights-blue;
    padding: 0.5rem 1rem;
    margin: 0.5rem;
    .remove-tag {
        margin-left: 0.2rem;
    }
    &:hover {
        cursor: pointer;
    }
}
.students-row {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    margin: 2rem 0rem;
}
.modal-header-title {
    font-weight: bold;
    font-size: 1.5rem;
}
.container {
    display: flex;
    height: 50rem;
    flex-direction: column;
    .filters {
        display: flex;
        flex-direction: column;
        border-radius: 6px;
        padding: 1rem 0;
        box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.5);
    }
    .filters-row {
        display: flex;
        flex-wrap: wrap;
        margin: 1rem 0rem;
        padding: 0rem 1rem;
        &__filter {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 1rem 0;

            label {
                white-space: nowrap;
                font-size: 11px;
                color: #979797;
                margin: 0 0.5rem;
            }
            .dd__ {
                &school,
                &risk-driver,
                &risk-level,
                &tags,
                &phases,
                &valid-phone,
                &opt-in,
                &received-messages {
                    width: 15rem;
                    height: 3rem;
                    display: flex;
                    align-items: center;
                }
            }
            > .dd__opt-in,
            .dd__received-messages {
                width: 9rem;
            }
            .dd__school {
                width: 30rem;
            }
            .dd__interventions {
                width: 35rem;
            }
        }
    }
}
.dd-icon {
    margin-left: 1rem;
    fill: #b4b4b5;
    &__enabled {
        fill: white;
    }
    &__open {
        fill: white;
        transform: rotate(180deg);
    }
}
.table-toolbar {
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0rem;
    > .count {
        flex: 1 0 auto;
    }
}
.add-filters {
    font-size: 30px;

    background-color: white;
    border: none;
    color: #068bff;
    margin-right: 10px;
    &:hover {
        cursor: pointer;
    }
}
</style>
