<template>
    <div class="card" v-bind:class="{ blueBorder: isPending }">
        <div class="header-row">
            <div class="row">
                <p class="header">{{ header }}</p>
                <p
                    class="badge"
                    v-bind:class="{ blue: isPending }"
                    v-tooltip="statusMessage.tooltip"
                >
                    {{ statusMessage.message }}
                </p>
            </div>
            <p>Date Triggered: {{ formattedDate }}</p>
        </div>
        <template v-if="showStudentInfo">
            <div class="row">
                <b>Name:</b>
                <button class="clickable" @click="toStudentPage">
                    {{ alert.respondentRef.displayName }}
                </button>
            </div>
            <div class="row">
                <b>Number:</b>
                <p>{{ alert.respondentRef.phoneNumber }}</p>
            </div>
            <div class="row">
                <b>Email:</b>
                <p>{{ alert.respondentRef.email }}</p>
            </div>
            <div class="row">
                <b>Student ID:</b>
                <p>{{ alert.respondentRef.studentId }}</p>
            </div>
        </template>
        <div v-for="msg in alert.priorMessages" v-bind:key="msg.objectId" class="row padded">
            <p>
                <b>{{ getMessageTypeText(msg.contentTypeId) }}</b>
                [{{ formatDate(msg.datetimeCreated) }}]
                {{ msg.text }}
            </p>
        </div>
        <div class="row" :class="!showStudentInfo ? 'extra-padded' : ''">
            <p>
                <b>Student Message (triggered alert): </b>
                [{{ formatDate(alert.datetimeCreated) }}]
                {{ alert.text }}
            </p>
        </div>
        <div v-for="msg in alert.subsequentMessages" v-bind:key="msg.objectId" class="row padded">
            <p>
                <b>{{ getMessageTypeText(msg.contentTypeId) }}</b>
                [{{ formatDate(msg.datetimeCreated) }}]
                {{ msg.text }}
            </p>
        </div>
        <div class="row buttons">
            <div v-if="statusUpdating">
                <LoadingSpinner />
            </div>

            <template v-else>
                <SelectableTile
                    v-if="isPending"
                    @click="markActionTaken"
                    ref="markAsActionTaken"
                    title="Mark as action taken"
                    supportingText="Alert has been acted upon in some way"
                    tileTheme="blueTile"
                />
                <SelectableTile
                    v-if="isPending"
                    @click="dismiss"
                    ref="dismiss"
                    title="Dismiss"
                    supportingText="Alert is either irrelevant or incorrect"
                    tileTheme="grayTile"
                />
                <SelectableTile
                    v-if="isActionTaken"
                    selected
                    title="Marked as action taken"
                    supportingText="Alert has been acted upon in some way"
                    @click="markPending('Action taken removed')"
                    ref="removeActionButton"
                    tileTheme="blueTile"
                >
                    <template #icon>
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                stroke="#0D6EC4"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </template>
                </SelectableTile>
                <SelectableTile
                    v-if="isDismissed"
                    selected
                    title="Marked as Dismissed"
                    supportingText="Alert is either irrelevant or incorrect"
                    @click="markPending('Alert undismissed')"
                    ref="undismiss"
                    tileTheme="grayTile"
                >
                    <template #icon>
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M15 9L9 15M9 9L15 15M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                stroke="#333333"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </template>
                </SelectableTile>
            </template>
        </div>
    </div>
</template>

<script>
import { nextTick } from 'vue';

import moment from 'moment';

import Alert from '@/services/alerts';

import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import SelectableTile from '@/components-deprecated/SelectableTile';
import { isExperimentalAccessibilityEnabled } from '@/lib/feature-flag';
import { vueMomentFilterReplacement } from '@/lib/vue-migration-helpers';

export default {
    name: 'AlertCard',
    components: {
        LoadingSpinner,
        SelectableTile
    },
    props: {
        alert: {
            type: Object,
            required: true
        },
        showStudentInfo: {
            type: Boolean,
            default: true
        },
        statusUpdating: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        header() {
            let triggerCategory =
                Alert.options.triggerCategories[this.alert.triggerCategory] + ' Alert';
            if (!isExperimentalAccessibilityEnabled) {
                switch (this.alert.triggerCategory) {
                    case 'MENTAL_HEALTH_TRIGGER':
                        triggerCategory += ' 🧠';
                        break;
                    case 'DROPOUT_TRIGGER':
                        triggerCategory += ' 🎓';
                        break;
                    case 'VIOLENCE_TRIGGER':
                        triggerCategory += ' 🚨';
                        break;
                    case 'COVID_TRIGGER':
                        triggerCategory += ' 😷';
                        break;
                }
            }

            return triggerCategory;
        },
        isExperimentalAccessibilityEnabled() {
            if (this.$store.state.user.schoolRef) {
                return isExperimentalAccessibilityEnabled(this.$store.state.user.schoolRef.id);
            }

            return false;
        },
        statusMessage() {
            const alertStatus = Alert.options.alertStatuses[this.alert.alertStatus];
            let message = alertStatus;
            let tooltip = null;
            if (!this.isPending && this.alert.lastEditedUserRef) {
                message = `${alertStatus} by ${this.alert.lastEditedUserRef.displayName
                    .split(' ')
                    .map(name => name[0])
                    .join('.') + '.'}`;
                tooltip = this.alert.lastEditedUserRef.displayName;
            }
            if (!this.isPending && this.alert.lastEditedUserEmail) {
                message = `${alertStatus} by ${this.alert.lastEditedUserEmail}`;
            }

            return {
                message,
                tooltip
            };
        },
        formattedDate() {
            return moment(this.alert.datetimeCreated).format('M/D/YYYY, h:mm:ss A');
        },
        isActionTaken() {
            return this.alert.alertStatus === Alert.options.ACTION_TAKEN;
        },
        isPending() {
            return this.alert.alertStatus === Alert.options.PENDING;
        },
        isDismissed() {
            return this.alert.alertStatus === Alert.options.DISMISSED;
        }
    },
    methods: {
        formatDate(date) {
            return vueMomentFilterReplacement(date, 'MM/DD/YYYY h:mma');
        },
        toStudentPage() {
            this.$emit('to-student-page', this.alert.respondentRef.id);
        },
        markActionTaken() {
            this.$emit(
                'on-status-update',
                this.alert.id,
                Alert.options.ACTION_TAKEN,
                'Alert marked as action taken'
            );
        },
        dismiss() {
            this.$emit(
                'on-status-update',
                this.alert.id,
                Alert.options.DISMISSED,
                'Alert dismissed'
            );
        },
        markPending(message) {
            this.$emit('on-status-update', this.alert.id, Alert.options.PENDING, message);
        },
        getMessageTypeText(contentTypeId) {
            const studentMessageContentType = 48;
            return contentTypeId === studentMessageContentType
                ? 'Student Message: '
                : 'Chatbot Message: ';
        }
    },
    watch: {
        statusUpdating(newValue, oldValue) {
            if (oldValue === true && newValue === false) {
                if (this.isActionTaken) {
                    nextTick(() => {
                        if (this.$refs.removeActionButton) {
                            this.$refs.removeActionButton.$el.focus();
                        }
                    });
                }
                if (this.isDismissed) {
                    nextTick(() => {
                        if (this.$refs.undismiss) {
                            this.$refs.undismiss.$el.focus();
                        }
                    });
                }
                if (this.isPending) {
                    nextTick(() => {
                        if (this.$refs.markAsActionTaken) {
                            this.$refs.markAsActionTaken.$el.focus();
                        }
                    });
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.card {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
    margin: -0.2rem 0rem;

    > * {
        margin-right: 0.5rem;
    }

    &.buttons {
        display: flex;
        align-items: center;
        min-height: 40px;
        margin-top: 16px;
    }
}

.header-row {
    @extend .row;

    justify-content: space-between;
    margin-bottom: 1.5rem;
}

.header {
    font-size: 1.15rem;
    font-weight: bold;
}

.badge {
    color: $dark-gray;
    background-color: rgba($dark-gray, 0.1);
    border-radius: 0.5rem;
    padding: 0rem 0.5rem;
    margin-left: 1rem;
    font-weight: bold;
}

.blue {
    @extend .badge;

    color: $primary-brand-color;
    background-color: rgba($primary-brand-color, 0.1);
}

.alert-card.blueBorder {
    border: 1px solid $primary-brand-color;
}

.clickable {
    cursor: pointer;
    text-decoration: underline;
    background: none;
    border: 0;

    &:focus {
        outline: 2px solid $edsights-blue;
    }
}

.padded {
    margin: 0.9rem 0;
}

.extra-padded {
    margin-bottom: 2.5rem;
}

.alert-button {
    padding: 1rem;
}
</style>
