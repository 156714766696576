<script setup>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner.vue';
import { BaseInputText, BaseButton, BaseFileUpload, BaseSelect } from '@edsights/ui-core';
import ButtonBack from '@/components-deprecated/ButtonBack.vue';
import { isWebChatbotEnabled, isWebChatbotSettingsPageEnabled } from '@/lib/feature-flag';
import { getSchoolWebChatbotSettings, updateSchoolWebChatbotSettings } from '@/api/school';
import Alerts from '@/lib/alerts';

const WEB_CHATBOT_SCREEN_POSITION = {
    TOP_LEFT: {
        value: 'TOP_LEFT',
        label: 'Top left'
    },
    TOP_RIGHT: {
        value: 'TOP_RIGHT',
        label: 'Top right'
    },
    BOTTOM_RIGHT: {
        value: 'BOTTOM_RIGHT',
        label: 'Bottom right'
    },
    BOTTOM_LEFT: {
        value: 'BOTTOM_LEFT',
        label: 'Bottom left'
    }
};
const webChatbotScreenPositionOptions = Object.values(WEB_CHATBOT_SCREEN_POSITION);

const store = useStore();
const isLoading = ref(false);
const isSaving = ref(false);
const isStaff = ref(true);
const uploadedMascotImageDataUrl = ref(null);
// The settings are set to some defaults. These get re-set when the school's settings once loaded.
const settings = ref({
    color: null,
    webChatbotScreenPosition: WEB_CHATBOT_SCREEN_POSITION.BOTTOM_RIGHT.value,
    mascotImageUrl: null,
    webChatbotMascotWidthPixels: 30,
    webChatbotMascotHeightPixels: 30
});
async function save() {
    try {
        isSaving.value = true;
        await updateSchoolWebChatbotSettings({
            schoolId: store.state.user.schoolRef.id,
            mascotImageDataUrl: uploadedMascotImageDataUrl.value,
            color: settings.value.color,
            webChatbotMascotWidthPixels: settings.value.webChatbotMascotWidthPixels,
            webChatbotMascotHeightPixels: settings.value.webChatbotMascotHeightPixels,
            webChatbotScreenPosition: settings.value.webChatbotScreenPosition,
            errorHandlerOptions: {
                rethrow: true,
                showAlert: true,
                overrideMessage: 'Error saving web chatbot settings.'
            }
        });
        Alerts.alert({
            type: 'success',
            message: 'Settings saved successfully.'
        });
    } finally {
        isSaving.value = false;
    }
}

function onMascotImageFileSelect(file) {
    uploadedMascotImageDataUrl.value = file;
}

async function loadSchoolWebChatbotSettings() {
    const school = await getSchoolWebChatbotSettings({
        schoolId: store.state.user.schoolRef.id
    });
    settings.value.color = school.color;
    settings.value.webChatbotScreenPosition = school.webChatbotScreenPosition;
    settings.value.webChatbotMascotWidthPixels = school.webChatbotMascotWidthPixels;
    settings.value.webChatbotMascotWidthPixels = school.webChatbotMascotWidthPixels;
    settings.value.mascotImageUrl = school.mascotUrl;
}

// Since this page can be navigated to directly, we must check if it should show:
const showPage = ref(false);
onMounted(async () => {
    try {
        const webChatbotEnabled = await isWebChatbotEnabled(store.state.user.schoolRef.id);
        const settingsPageEnabled = webChatbotEnabled
            ? await isWebChatbotSettingsPageEnabled(store.state.user.schoolRef.id)
            : false;

        if (webChatbotEnabled && settingsPageEnabled) {
            showPage.value = true;
            await loadSchoolWebChatbotSettings();
        }
    } finally {
        isLoading.value = false;
    }
});
</script>

<template>
    <div class="manage-web-chatbot container">
        <ButtonBack />

        <LoadingSpinner v-if="isLoading" />
        <div v-if="showPage">
            <div class="content">
                <h1 class="h2">Web Chatbot Settings</h1>
                <p>
                    Below are the web chatbot configuration settings. This page is "view only" so if
                    you need to make edits, reach out to your EdSights point of contact.
                </p>

                <section class="section-container">
                    <h2 class="h3">Mascot Image</h2>

                    <div class="row-container">
                        <div class="mascot-image">
                            <div class="mascot-image-previews">
                                <div class="mascot-image-preview">
                                    <img
                                        width="100px"
                                        :src="
                                            uploadedMascotImageDataUrl
                                                ? uploadedMascotImageDataUrl
                                                : settings.mascotImageUrl
                                        "
                                        alt="The mascot image/avatar"
                                    />
                                    Original
                                </div>
                                <div class="mascot-image-preview">
                                    <img
                                        width="40px"
                                        :src="
                                            uploadedMascotImageDataUrl
                                                ? uploadedMascotImageDataUrl
                                                : settings.mascotImageUrl
                                        "
                                        alt="The mascot image/avatar"
                                    />
                                    Preview
                                </div>
                            </div>
                            <BaseFileUpload
                                label="Upload new file (.png)"
                                accept="image/png,image/jpg,image/jpeg,image/webp"
                                @onSelect="onMascotImageFileSelect"
                            />
                        </div>
                    </div>
                </section>
                <section class="section-container">
                    <h2 class="h3">Color</h2>

                    <div class="row-container">
                        <BaseInputText
                            :readOnly="!isStaff"
                            v-model.trim="settings.color"
                            label="Primary color (hex color)"
                        />
                        <div class="color-preview" :style="{ backgroundColor: settings.color }" />
                    </div>
                </section>
                <section class="section-container">
                    <h2 class="h3">Positioning</h2>
                    <div class="row-container">
                        <BaseSelect
                            v-model="settings.webChatbotScreenPosition"
                            label="Position on website"
                            :options="webChatbotScreenPositionOptions"
                        />
                    </div>
                </section>
                <div class="button-container">
                    <BaseButton @click="save" label="Save" />
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.manage-web-chatbot {
    .mascot-image {
        .mascot-image-previews {
            display: flex;
            align-items: flex-end;
            .mascot-image-preview {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }

    .color-preview {
        border: 1px solid red;
        width: 50px;
        height: 50px;
    }
    .button-container {
        display: flex;
        justify-content: flex-end;
    }
}
.section-container {
    margin-top: 25px;
    margin-left: 25px;
}

.row-container {
    display: flex;
    justify-content: space-between;
    & > * {
        margin-top: 20px;
        width: 40%;
        max-width: 40%;
    }
}
.h3 {
    color: black;
    font-size: initial;
}
</style>
