<template>
    <div role="rowgroup">
        <div role="row" class="header">
            <div class="header__checkbox" role="columnheader">
                <label class="select">
                    <BaseCheckbox
                        ref="checkbox"
                        class="checkbox"
                        :modelValue="selected"
                        @update:modelValue="toggleSelected"
                        isMedium
                        ariaLabel="Select All"
                    />
                    {{ isExperimentalAccessibilityEnabled ? 'Select' : '' }}
                </label>
            </div>
            <div class="header__name" role="columnheader">{{ name }}</div>
            <div class="header__id" role="columnheader">{{ studentId }}</div>
            <div class="header__level" role="columnheader">{{ riskLevel }}</div>
            <!-- showRiskDrivers is false on Interventions Page -->
            <div v-if="showRiskDrivers" class="header__driver" role="columnheader">
                <span>{{ riskDriver }}</span>
                <RiskDriverExplanation v-if="!isExperimentalAccessibilityEnabled" />
            </div>
            <div v-if="showDateAdded" class="header__date-added" role="columnheader">
                {{ dateAdded }}
            </div>
            <div v-if="showOutreachStatus" class="header__outreach-status" role="columnheader">
                {{ outReachStatus }}
            </div>
        </div>
    </div>
</template>

<script>
import { BaseCheckbox } from '@edsights/ui-core';
import RiskDriverExplanation from '@/components-deprecated/RiskDriverExplanation';
import { isExperimentalAccessibilityEnabled } from '@/lib/feature-flag';

export default {
    name: 'StudentListItemHeader',
    components: {
        BaseCheckbox,
        RiskDriverExplanation
    },
    props: {
        selected: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: 'Name'
        },
        studentId: {
            type: String,
            default: 'ID'
        },
        riskLevel: {
            type: String,
            default: 'Risk Level'
        },
        riskDriver: {
            type: String,
            default: 'Risk Driver'
        },
        outReachStatus: {
            type: String,
            default: 'Action Taken'
        },
        dateAdded: {
            type: String,
            default: 'Date Added'
        },
        showRiskDrivers: {
            type: Boolean,
            default: true
        },
        showOutreachStatus: {
            type: Boolean,
            default: false
        },
        showDateAdded: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        toggleSelected(value) {
            if (value) {
                this.$emit('select');
            } else {
                this.$emit('unselect');
            }
        }
    },
    computed: {
        isExperimentalAccessibilityEnabled() {
            if (this.$store.state.user.schoolRef) {
                return isExperimentalAccessibilityEnabled(this.$store.state.user.schoolRef.id);
            }

            return false;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.header {
    display: flex;
    justify-content: flex-start;
    padding: 4px 15px 4px 15px;
    height: 40px;
    border-bottom: none;
    font-size: 14px;
    background-color: $white-blue;
    align-items: center; // centers titles vertically
    position: sticky;
    top: 0;
    z-index: 9;

    & > * {
        display: inline-block;
        margin-right: 2.5%;
    }

    .select {
        display: flex;
    }

    &__checkbox {
        width: 5%;
    }

    &__level {
        flex-grow: 1; // flex-grow dynamically determines width
        display: inline-flex;
        align-items: center;
        width: 15%;
    }

    &__name {
        flex-grow: 1.9;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 25%;
    }

    &__id {
        flex-grow: 3.5;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 20%;
    }

    &__driver {
        flex-grow: 0.9;
        display: inline-flex;
        align-items: center;
        width: 30%;
        span {
            margin-right: 4px;
        }

        &_img {
            width: 16px;
        }
    }

    &__date-added {
        width: 20%;
    }

    &__outreach-status {
        width: 20%;
    }
}
</style>
