<template>
    <div class="header">
        <div class="header__checkbox">
            <BaseCheckbox
                ref="checkbox"
                class="checkbox"
                :modelValue="selected"
                @update:modelValue="toggleSelected"
                isMedium
                ariaLabel="Select All"
            />
        </div>
        <div class="header__name">{{ name }}</div>
        <div class="header__email">{{ email }}</div>
        <div class="header__permission">{{ permission }}</div>
        <div class="header__kb">{{ knowledgeBase }}</div>
        <div class="header__action">&nbsp;</div>
    </div>
</template>

<script>
import { BaseCheckbox } from '@edsights/ui-core';

export default {
    name: 'AdminListItemHeader',
    components: {
        BaseCheckbox
    },
    props: {
        name: {
            type: String,
            default: 'Name'
        },
        email: {
            type: String,
            default: 'Email'
        },
        permission: {
            type: String,
            default: 'Permission'
        },
        knowledgeBase: {
            type: String,
            default: 'Knowledge Base Access'
        },
        selected: {
            type: Boolean,
            default: false
        }
    },
    watch: {},
    methods: {
        toggleSelected(value) {
            if (value) {
                this.$emit('select');
            } else {
                this.$emit('unselect');
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: $white-blue;

    & > * {
        width: 15%;
    }

    &__checkbox {
        width: 25px;
    }

    &__name {
        width: 20%;
    }

    &__email {
        width: 20%;
    }

    &__permission {
        width: 12%;
    }

    &__kb {
        width: 8%;
        text-align: center;
    }

    &__action {
        width: 18%;
    }
}
</style>
