<template>
    <modal
        name="bulk-retire-students"
        height="auto"
        :clickToClose="false"
        :stack="false"
        tabIndex="-1"
    >
        <div class="modal-container">
            <div class="modal-body">
                <div class="container">
                    <h2>Step 1: Select a school</h2>
                    <div class="school-dropdown">
                        <v-select
                            :options="schools"
                            v-model="selectedSchool"
                            label="name"
                            @update:modelValue="schoolSelectionHandler"
                            placeholder="Search for a school..."
                            :clearable="false"
                        />
                    </div>
                </div>
                <div class="container upload">
                    <h2>Step 2: Upload a CSV with student emails</h2>
                    <span>one line per row please</span>
                    <div class="select-upload">
                        <ChooseFileCancelable
                            :readAsString="false"
                            @loaded="onFileLoaded"
                            :disabled="!schoolId"
                        >
                            <template v-slot:message>
                                <h3 class="upload-message">Upload your Spreadsheet</h3>
                            </template>
                        </ChooseFileCancelable>
                    </div>
                    <div style="margin-top: 3rem;" v-if="!fileLoading">
                        <h2>
                            CSV contains 
                            <span class="student-count">{{ this.users.length }} active students</span>.
                        </h2>
                    </div>
                    <div style="margin-top: 3rem;" v-else>
                        <LoadingSpinner />
                    </div>
                </div>
            </div>
            <div class="footer">
                <Button styleType="secondary" class="close-btn" @click="onClose">Close</Button>
                <loading-spinner-button
                    class="save-btn"
                    text="Retire Users"
                    :loading="loading"
                    @click="onSubmitBulk"
                    :disabled="!schoolId || !users.length"
                />
            </div>
        </div>
    </modal>
</template>

<script>
import ChooseFileCancelable from '@/components-deprecated/ChooseFileCancelable';
import LoadingSpinnerButton from '@/components-deprecated/LoadingSpinnerButton.vue';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import Button from '@/components-deprecated/global/Button.vue';
import Student from '@/services/students';
import * as SchoolApi from '@/api/school';
import * as UserApi from '@/api/user';

export default {
    name: 'BulkRetireUsersModal',
    components: {
        ChooseFileCancelable,
        LoadingSpinnerButton,
        LoadingSpinner,
        Button
    },
    data() {
        return {
            loading: false,
            fileLoading: false,
            schools: [],
            users: [],
            schoolId: '',
            selectedSchool: '',
        };
    },
    created() {
        this.loadSchools();
    },
    methods: {
        onClose() {
            this.resetFields();
            this.$modal.hide('bulk-retire-students');
        },
        resetFields() {
            this.schoolId = '';
            this.selectedSchool = '';
            this.users = [];
        },
        async loadSchools() {
            this.schools = (
                await SchoolApi.listSchools({ queryParams: { pageSize: 500, isActive: true } })
            ).results;
        },
        schoolSelectionHandler(value) {
            this.schoolId = value.id;
        },
        async onFileLoaded(file) {
            this.fileLoading = true;
            if (!file) {
                this.users = [];
                return;
            }

            const res = await Student.api.getStudentListFromCsv(this.schoolId, file, false);
            this.fileLoading = false;
            this.users = res.ids;
        },
        async onSubmitBulk() {
            this.loading = true;
            if (this.users.length === 0) {
                this.$Alert.alert({
                    type: 'error',
                    message: `Whoops. Student list is empty.`,
                    timeout: 5000
                });
                this.loading = false;
                return;
            }

            const response = await UserApi.bulkRetireStudents({
                studentIds: this.users,
                schoolId: this.schoolId,
                errorHandlerOptions: {
                    overrideMessage: 'Whoops. There was a problem deactivating these students.'
                }
            });
            if (response) {
                this.$Alert.alert({
                    type: 'success',
                    message: `${response.length} students deactivated!`,
                    timeout: 5000
                });
                this.onClose();
            }
            this.loading = false;
        },
    }
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins/buttons';

.modal-container {
    padding: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 500px;
}

.modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #E3EDED;
    box-shadow: 0 5px 5px 0 #F6F9FE;
    border-radius: 6px;
    width: 90%;
    height: 10rem;

    .upload {
        height: 20rem;
    }
}

.school-dropdown {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    width: 30rem;
}

.container:hover,
.container:hover {
    box-shadow: 0 0 5px 0 #068BFF;
}

.select-upload {
    margin-top: 1rem;
    margin-right: 2.5rem;
}

.footer {
    margin-top: auto; 
    display: flex;
    justify-content: flex-end;
    margin: 2rem 0 1rem 0;
}

.save-btn {
    @include base-button();
}

.close-btn {
    margin-right: 1rem;
}

.student-count {
    color: $edsights-blue;
}
</style>
