<template>
    <div class="tag-item">
        <div @click.stop class="checkbox">
            <BaseCheckbox
                isMedium
                ref="checkbox"
                :modelValue="selected"
                @update:modelValue="toggleSelected"
            />
        </div>

        <div>
            {{ tag.name }}
        </div>

        <div>
            {{ tag.studentCount }}
        </div>

        <div>
            <Button styleType="off" unbordered @click="onClickViewStudents">
                View Students
            </Button>
        </div>
    </div>
</template>

<script>
import Button from '@/components-deprecated/Button';
import { BaseCheckbox } from '@edsights/ui-core';
export default {
    name: 'ManageAdminTagsListItem',
    components: {
        Button,
        BaseCheckbox
    },
    props: {
        tag: {
            required: true,
            validator: value => {
                return (
                    typeof value === 'object' &&
                    typeof value.name === 'string' &&
                    typeof value.studentCount === 'string'
                );
            }
        },
        selected: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        toggleSelected(value) {
            if (value) {
                this.$emit('select', this.tag);
            } else {
                this.$emit('unselect', this.tag);
            }
        },
        onClickViewStudents() {
            this.$emit('view-students');
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';

.tag-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;

    & > * {
        width: 33%;
    }

    .checkbox {
        width: 45px;
    }
}
</style>
