import { createRouter, createWebHistory } from 'vue-router';
import Auth from '@/services/auth';
import Error404 from '@/views/Error404';
import AlertAction from '@/views/AlertAction';
import Login from '@/views/authentication/Login';
import ForgotPassword from '@/views/authentication/ForgotPassword';
import ForgotPasswordSuccess from '@/views/authentication/ForgotPasswordSuccess';
import PasswordResetConfirm from '@/views/authentication/PasswordResetConfirm';
import MagicLink from '@/views/MagicLink';

// Admin Screens
import ManageStudents from '@/views/AdminSettings/ManageStudents';
import ManageStudentsByTag from '@/views/AdminSettings/ManageStudentsByTag';
import ManageCoAdmins from '@/views/AdminSettings/ManageCoAdmins';
import ManageAlerts from '@/views/AdminSettings/ManageAlerts';
import InviteCoAdmins from '@/views/AdminSettings/InviteCoAdmins';
import ManageTags from '@/views/AdminSettings/ManageTags';
import KeyInformation from '@/views/AdminSettings/KeyInformation';
import Overview from '@/views/AdminSettings/Overview';
import Chatbot from '@/views/Chatbot/Home';

// Admin student views
import AnalysisStudents from '@/views/students/AnalysisStudents';
import StudentDetail from '@/views/students/StudentDetail';
import Alerts from '@/views/students/Alerts';

// Admin insight views
import InsightsPage from '@/views/insights/InsightsPage';
import InsightDetail from '@/views/insights/InsightDetail';

// Admin Interventions views
import InterventionsPage from '@/views/AdminSettings/InterventionsPage';
import InterventionDetails from '@/views/AdminSettings/InterventionDetails';

import Resources from '@/views/AdminSettings/Resources';
import Settings from '@/views/AdminSettings/Settings';
import KnowledgeBase from '@/views/KnowledgeBase';
import KnowledgeBasev2 from '@/views/KnowledgeBasev2';
import StudentUploadsAdmin from '@/views/StudentUploadsAdmin';
import MessagingTracks from '@/views/MessagingTracks';

// Staff screens
import ManageChatbot from '@/views/staff/ManageChatbot';
import ChatbotFlowList from '@/views/staff/ChatbotFlowList/ChatbotFlowList';
import KnowledgeAnalyticsPage from '@/views/KnowledgeAnalytics/KnowledgeAnalyticsPage';

import ChatbotMessagingPage from '@/views/Activity/ChatbotMessaging/ChatbotMessagingPage';
import EngagementAnalyticsPage from '@/views/Activity/EngagementAnalytics/EngagementAnalyticsPage';
import ChatbotTextsPage from '@/views/staff/ChatbotTextsPage';
import StaffStudentsPage from '@/views/staff/StaffStudentsPage';
import Uploadsv1Page from '@/views/staff/Uploadsv1Page';
import UploadsPage from '@/views/staff/UploadsPage';
import StaffToolsList from '@/views/staff/StaffTools/StaffToolsList';
import StaffEnterSchoolPage from '@/views/staff/StaffEnterSchoolPage';
import ManageAdminTags from '@/views/AdminSettings/ManageAdminTags/ManageAdminTags';
import ManageAdminStudents from '@/views/AdminSettings/ManageAdminStudents/ManageAdminStudents';
import ManageWebChatbot from '@/views/AdminSettings/ManageWebChatbot';
import ChatbotFlowBuilder from '@/views/ChatbotFlowBuilder/ChatbotFlowBuilder';
import Sitemap from '@/views/Sitemap';
import AccessibilityStatement from '@/views/AccessibilityStatement';
import StudentVoiceScorePage from '@/views/StudentVoiceScorePage/StudentVoiceScorePage';
import { isUIFeatureFlagEnabled } from '@/lib/feature-flag';

export default createRouter({
    mode: 'history',
    linkActiveClass: 'active',
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {
            path: '/login',
            name: 'Login',
            component: Login,
            beforeEnter: Auth.requireUnAuth,
            meta: {
                title: 'Login'
            }
        },
        {
            path: '/forgot-password',
            name: 'ForgotPassword',
            component: ForgotPassword,
            meta: {
                title: 'Forgot Password'
            }
        },
        {
            path: '/forgot-password/success',
            name: 'ForgotPasswordSuccess',
            component: ForgotPasswordSuccess,
            beforeEnter: Auth.requireUnAuth,
            meta: {
                title: 'Password Success'
            }
        },
        {
            path: '/password/reset/confirm/:uid/:token',
            name: 'PasswordResetConfirm',
            component: PasswordResetConfirm,
            beforeEnter: Auth.requireUnAuth,
            meta: {
                title: 'Password Confirm'
            }
        },
        {
            path: '/magic-link',
            name: 'MagicLink',
            component: MagicLink,
            meta: {
                title: 'Welcome'
            }
        },
        {
            path: '/',
            name: 'Home',
            component: Overview,
            beforeEnter: Auth.requireAdminauth,
            meta: {
                title: 'Overview'
            }
        },
        {
            path: '/manage-students',
            name: 'ManageStudents',
            component: ManageStudents,
            beforeEnter: Auth.requireAdminauth,
            meta: {
                title: 'Manage Students'
            }
        },
        {
            path: '/manage-students/:tag',
            name: 'ManageStudentsByTag',
            component: ManageStudentsByTag,
            beforeEnter: Auth.requireAdminauth,
            meta: {
                title: 'Manage Students by Tag'
            }
        },
        {
            path: '/manage-coadmins',
            name: 'ManageCoAdmins',
            component: ManageCoAdmins,
            beforeEnter: Auth.requireAdminauth,
            meta: {
                title: 'Manage Co-Admins'
            }
        },
        {
            path: '/manage-coadmin-tags/:adminId',
            name: 'ManageAdminTags',
            component: ManageAdminTags,
            beforeEnter: Auth.requireAdminauth,
            meta: {
                title: 'Manage Admin Tags'
            }
        },
        {
            path: '/manage-coadmin-students/:adminId/:tagId',
            name: 'ManageAdminStudents',
            component: ManageAdminStudents,
            beforeEnter: Auth.requireAdminauth,
            meta: {
                title: 'Manage Admin Students'
            }
        },
        {
            path: '/manage-web-chatbot',
            name: 'ManageWebChatbot',
            component: ManageWebChatbot,
            beforeEnter: Auth.requireAdminauth,
            meta: {
                title: 'Manage Web Chatbot'
            }
        },
        {
            path: '/staff/chatbot',
            name: 'ManageChatbot',
            component: ManageChatbot,
            beforeEnter: Auth.requireStaffAuth,
            meta: {
                title: 'Manage Chatbot'
            }
        },
        {
            path: '/staff/chatbot-workflow/',
            name: 'ChatbotFlowList',
            component: ChatbotFlowList,
            beforeEnter: Auth.requireStaffAuth,
            meta: {
                title: 'Chatbot'
            }
        },
        {
            path: '/staff',
            name: 'StaffHome',
            beforeEnter: Auth.requireStaffAuth,
            redirect: to => {
                return { name: 'ChatbotFlowList' };
            },
            meta: {
                title: 'Home'
            }
        },
        // Create a new flow:
        {
            path: '/chatbot-flow',
            name: 'ChatbotFlowBuilderCreate',
            component: ChatbotFlowBuilder,
            beforeEnter: Auth.requireStaffAuth,
            meta: {
                title: 'Chatbot Flow'
            }
        },
        // Create a new reusable template:
        {
            path: '/chatbot-flow/template',
            name: 'ChatbotFlowBuilderCreateTemplate',
            component: ChatbotFlowBuilder,
            beforeEnter: Auth.requireStaffAuth,
            meta: {
                title: 'Chatbot Flow'
            }
        },
        // Edit a flow or reusable template:
        {
            path: '/chatbot-flow/:flowId',
            name: 'ChatbotFlowBuilderEdit',
            component: ChatbotFlowBuilder,
            beforeEnter: Auth.requireStaffAuth,
            meta: {
                title: 'Chatbot Flow'
            }
        },
        // Create a new flow from an existing reusable template:
        {
            path: '/chatbot-flow/create-from-template/:templateFlowId',
            name: 'ChatbotFlowBuilderCreateFromTemplate',
            component: ChatbotFlowBuilder,
            beforeEnter: Auth.requireStaffAuth,
            meta: {
                title: 'Chatbot Flow'
            }
        },
        {
            path: '/staff/chatbot-texts/',
            name: 'ChatbotTextsPage',
            component: ChatbotTextsPage,
            beforeEnter: Auth.requireStaffAuth,
            meta: {
                title: 'Texts'
            }
        },
        {
            path: '/staff/students/',
            name: 'StaffStudentsPage',
            component: StaffStudentsPage,
            beforeEnter: Auth.requireStaffAuth,
            meta: {
                title: 'Students'
            }
        },
        {
            path: '/staff/uploads-v1/',
            name: 'Uploadsv1Page',
            component: Uploadsv1Page,
            beforeEnter: Auth.requireStaffAuth,
            meta: {
                title: 'Uploads'
            }
        },
        {
            path: '/staff/uploads/',
            name: 'UploadsPage',
            component: UploadsPage,
            beforeEnter: Auth.requireStaffAuth,
            props: {
                integrationOrigin: 'staff'
            },
            meta: {
                title: 'Uploads v2'
            }
        },
        {
            path: '/staff/uploads-sftp/',
            name: 'UploadsSFTPPage',
            component: UploadsPage,
            beforeEnter: Auth.requireStaffAuth,
            props: {
                integrationOrigin: 'sftp'
            },
            meta: {
                title: 'SFTP'
            }
        },
        {
            path: '/staff/staff-tools/',
            name: 'StaffToolsList',
            component: StaffToolsList,
            beforeEnter: Auth.requireStaffAuth,
            meta: {
                title: 'Staff Tools'
            }
        },
        {
            path: '/staff/enter-school/',
            name: 'StaffEnterSchoolPage',
            component: StaffEnterSchoolPage,
            beforeEnter: Auth.requireStaffAuth,
            meta: {
                title: 'Enter School'
            }
        },
        {
            path: '/invite-coadmins',
            name: 'InviteCoAdmins',
            component: InviteCoAdmins,
            beforeEnter: Auth.requireAdminauth,
            meta: {
                title: 'Invite'
            }
        },
        {
            path: '/manage-tags',
            name: 'ManageTags',
            component: ManageTags,
            beforeEnter: Auth.requireAdminauth,
            meta: {
                title: 'Manage Tags'
            }
        },
        {
            path: '/key-information',
            name: 'KeyInformation',
            component: KeyInformation,
            beforeEnter: Auth.requireAdminauth,
            meta: {
                title: 'Account Information'
            }
        },
        {
            path: '/manage-alerts',
            name: 'ManageAlerts',
            component: ManageAlerts,
            beforeEnter: Auth.requireAdminauth,
            meta: {
                title: 'Manage Alerts'
            }
        },
        {
            path: '/overview',
            name: 'Overview',
            component: Overview,
            beforeEnter: Auth.requireAdminauth,
            meta: {
                title: 'Overview'
            }
        },
        {
            path: '/analysis-students',
            name: 'AnalysisStudents',
            component: AnalysisStudents,
            beforeEnter: Auth.requireAdminauth,
            meta: {
                title: 'Students'
            }
        },
        {
            path: '/analysis-students/search=:searchQuery',
            name: 'AnalysisStudentsWithSearch',
            component: AnalysisStudents,
            beforeEnter: Auth.requireAdminauth,
            meta: {
                title: 'Students'
            }
        },
        {
            path: '/alerts',
            name: 'Alerts',
            component: Alerts,
            beforeEnter: Auth.requireAdminauth,
            meta: {
                title: 'Alerts'
            }
        },
        {
            path: '/chatbot',
            name: 'Chatbot',
            component: Chatbot,
            beforeEnter: Auth.requireAdminauth,
            meta: {
                title: 'Chatbot'
            }
        },
        {
            path: '/insights/retention',
            name: 'InsightsPage',
            component: InsightsPage,
            beforeEnter: Auth.requireAdminauth,
            meta: {
                title: 'Retention Insights'
            }
        },
        {
            path: '/insights/retention/:id',
            name: 'InsightDetail',
            component: InsightDetail,
            beforeEnter: Auth.requireAdminauth,
            meta: {
                title: 'Insight Detail'
            }
        },
        {
            path: isUIFeatureFlagEnabled('VUE_APP_FEATURE_FLAG_RELOCATE_KNOWLEDGE_ANALYTICS')
                ? '/activity/knowledge'
                : '/insights/knowledge',
            name: 'KnowledgeAnalyticsPage',
            component: KnowledgeAnalyticsPage,
            beforeEnter: Auth.requireAdminauth,
            meta: {
                title: 'Knowledge Analytics'
            }
        },
        {
            path: '/insights/student-voice-score',
            name: 'StudentVoiceScorePage',
            component: StudentVoiceScorePage,
            beforeEnter: Auth.requireAdminauth,
            meta: {
                title: 'Student Voice Score'
            }
        },
        {
            path: '/activity/chatbot-messaging',
            name: 'ChatbotMessagingPage',
            component: ChatbotMessagingPage,
            beforeEnter: Auth.requireAdminauth,
            meta: {
                title: 'Chatbot Messaging'
            }
        },
        {
            path: '/activity/engagement',
            name: 'EngagementAnalyticsPage',
            component: EngagementAnalyticsPage,
            beforeEnter: Auth.requireAdminauth,
            meta: {
                title: 'Student Engagement'
            }
        },
        {
            path: '/interventions',
            name: 'InterventionsPage',
            component: InterventionsPage,
            beforeEnter: Auth.requireAdminauth,
            meta: {
                title: 'Interventions'
            }
        },
        {
            path: '/interventions/:id',
            name: 'InterventionDetails',
            component: InterventionDetails,
            beforeEnter: Auth.requireAdminauth,
            meta: {
                title: 'Intervention Details'
            }
        },
        {
            path: '/resources',
            name: 'Resources',
            component: Resources,
            beforeEnter: Auth.requireAdminauth,
            meta: {
                title: 'Resources'
            }
        },
        {
            path: '/settings',
            name: 'Settings',
            component: Settings,
            beforeEnter: Auth.requireAdminauth,
            meta: {
                title: 'Settings'
            }
        },
        {
            path: '/student-detail/:id',
            name: 'StudentDetail',
            component: StudentDetail,
            beforeEnter: Auth.requireAdminauth,
            meta: {
                title: 'Student Detail'
            }
        },
        {
            path: '/knowledge-base',
            name: 'KnowledgeBase',
            component: KnowledgeBase,
            beforeEnter: Auth.requireAdminauth,
            meta: {
                title: 'Knowledge Base'
            }
        },
        {
            path: '/knowledge-base-v2',
            name: 'KnowledgeBasev2',
            component: KnowledgeBasev2,
            beforeEnter: Auth.requireAdminauth,
            meta: {
                title: 'Knowledge Base'
            }
        },
        {
            path: '/messaging-tracks',
            name: 'MessagingTracks',
            component: MessagingTracks,
            beforeEnter: Auth.requireAdminauth,
            meta: {
                title: 'Messaging Tracks'
            }
        },
        {
            path: '/sitemap',
            name: 'Sitemap',
            component: Sitemap,
            meta: {
                title: 'Sitemap'
            }
        },
        {
            path: '/accessibility-statement',
            name: 'AccessibilityStatement',
            component: AccessibilityStatement,
            meta: {
                title: 'Accessibility Statement'
            }
        },
        {
            path: '/student-uploads',
            name: 'StudentUploadsAdmin',
            component: StudentUploadsAdmin,
            beforeEnter: Auth.requireAdminauth,
            meta: {
                title: 'Manage Students'
            }
        },
        {
            path: '/alert-action',
            name: 'AlertAction',
            component: AlertAction,
            meta: {
                analytics: {
                    pageviewTemplate(route) {
                        return {
                            title: 'Alert Action'
                        };
                    }
                }
            }
        },
        {
            path: '/error404',
            name: 'Error404',
            component: Error404,
            meta: {
                title: 'Error'
            }
        },
        { path: '/:catchAll(.*)', redirect: '/error404' }
    ]
});
