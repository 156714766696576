<template>
    <div class="header">
        <div class="header__checkbox">
            <BaseCheckbox
                ref="checkbox"
                class="checkbox"
                :modelValue="selected"
                @update:modelValue="toggleSelected"
                isMedium
                ariaLabel="Select All"
            />
        </div>
        <div>Tag Name</div>
        <div>Student Count</div>
        <div>Actions</div>
    </div>
</template>

<script>
import { BaseCheckbox } from '@edsights/ui-core';

export default {
    name: 'ManageAdminTagsListHeader',
    components: {
        BaseCheckbox
    },
    props: {
        selected: {
            type: Boolean,
            default: false
        }
    },
    watch: {},
    methods: {
        toggleSelected(value) {
            if (value) {
                this.$emit('select');
            } else {
                this.$emit('unselect');
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: $white-blue;

    & > * {
        width: 33%;
    }

    &__checkbox {
        width: 45px;
    }
}
</style>
