<template>
    <div class="grouped-dropdown-container">
        <div class="input-container">
            <button class="input-group cursor-pointer" @click.stop="toggleMenu">
                <label class="input-label faded">{{ label }}</label>
                <svg width="10px" height="10px" class="arrow-reversed" viewBox="0 0 5.9 17.51">
                    <use xlink:href="#icon-arrow-right-wide" />
                </svg>
            </button>
            <TransitionExpand>
                <div class="transition-container" v-if="showMenu">
                    <LoadingSpinner v-if="groupedOptionsLoading" />
                    <div v-else class="dropdown">
                        <div v-if="showSearchInput" class="input-group bordered search-bar">
                            <input
                                :placeholder="searchPlaceholder"
                                v-model="searchText"
                                @input="onSearchDebounced"
                            />
                            <svg class="icon-search" viewBox="0 0 50 50">
                                <use xlink:href="#icon-search" />
                            </svg>
                        </div>
                        <template v-if="this.searchLoading">
                            <br />
                            <LoadingSpinner />
                        </template>
                        <div v-else class="group-container" tabindex="0" role="listbox">
                            <div
                                :key="group[groupValueKey]"
                                v-for="group in renderedGroupedOptions"
                                role="option"
                            >
                                <button class="group" @click.stop="onGroupClick(group)">
                                    <p>{{ group[groupLabelKey] }}</p>
                                    <svg
                                        width="10px"
                                        height="10px"
                                        class="arrow"
                                        viewBox="0 0 5.9 17.51"
                                        :class="{
                                            'arrow-reversed': !activeGroups.includes(group)
                                        }"
                                    >
                                        <use xlink:href="#icon-arrow-right-wide" />
                                    </svg>
                                </button>
                                <TransitionExpand v-if="activeGroups.includes(group)">
                                    <div class="option-container">
                                        <label
                                            :key="option[optionValueKey]"
                                            v-for="option in group[groupOptionsKey]"
                                            class="option"
                                        >
                                            <input
                                                type="checkbox"
                                                class="option-checkbox"
                                                :checked="isOptionSelected(option)"
                                                @click="() => onOptionSelected(option)"
                                            />
                                            {{ option[optionLabelKey] }}
                                        </label>
                                    </div>
                                </TransitionExpand>
                            </div>
                            <Paginator
                                v-if="hasNextPage"
                                :loading="nextPageLoading"
                                @next-page="onNextPage"
                            />
                        </div>
                    </div>
                </div>
            </TransitionExpand>
        </div>
    </div>
</template>

<script>
import TransitionExpand from '@/components-deprecated/TransitionExpand';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import Paginator from '@/components-deprecated/Paginator';
import { debounce } from '@/services/utils';

export default {
    name: 'GroupedDropdown',
    components: {
        TransitionExpand,
        LoadingSpinner,
        Paginator
    },
    props: {
        groupedOptions: {
            type: Array,
            default: () => []
        },
        groupedOptionsLoading: {
            type: Boolean,
            default: false
        },
        selectedOptions: {
            type: Array,
            default: () => []
        },
        searchLoading: {
            type: Boolean,
            default: false
        },
        hasNextPage: {
            type: Boolean,
            default: false
        },
        nextPageLoading: {
            type: Boolean,
            default: false
        },
        isMultiSelect: {
            type: Boolean,
            default: true
        },
        label: {
            type: String,
            default: 'Select'
        },
        searchPlaceholder: {
            type: String,
            default: 'Search'
        },
        groupLabelKey: {
            type: String,
            default: 'label'
        },
        groupValueKey: {
            type: String,
            default: 'value'
        },
        groupOptionsKey: {
            type: String,
            default: 'options'
        },
        optionLabelKey: {
            type: String,
            default: 'label'
        },
        optionValueKey: {
            type: String,
            default: 'value'
        },
        // Enable to display a search input and internally filter groupedOptions by their groupLabel (debounced).
        enableInternalGroupLabelSearch: {
            type: Boolean,
            default: false
        },
        // Enable to display a search input and emit a @search event for backend or custom client-side filtering (debounced).
        enableExternalSearch: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            activeGroups: [],
            // "internal" naming is so we don't clash with the selectedOptions prop:
            internalSelectedOptions: [],
            onSearchDebounced: () => {},
            showMenu: false,
            searchText: '',
            searchTextDebounced: ''
        };
    },
    async created() {
        this.onSearchDebounced = debounce(this.onSearch, 700);
    },
    beforeUnmount() {
        this.removeWindowClickListener();
    },
    computed: {
        renderedGroupedOptions() {
            if (!Array.isArray(this.groupedOptions)) {
                return [];
            }
            if (this.enableInternalGroupLabelSearch && this.searchTextDebounced) {
                return this.groupedOptions.filter(group =>
                    group[this.groupLabelKey]
                        .trim()
                        .toLowerCase()
                        .includes(this.searchTextDebounced.trim().toLowerCase())
                );
            }
            return this.groupedOptions;
        },
        showSearchInput() {
            return this.enableInternalGroupLabelSearch || this.enableExternalSearch;
        }
    },
    methods: {
        isOptionSelected(option) {
            return this.internalSelectedOptions.some(selectedOption => {
                return selectedOption[this.optionValueKey] === option[this.optionValueKey];
            });
        },
        onGroupClick(group) {
            if (this.activeGroups.includes(group)) {
                this.activeGroups = this.activeGroups.filter(
                    g => g[this.groupValueKey] !== group[this.groupValueKey]
                );
            } else {
                this.activeGroups.push(group);
            }
        },
        onOptionSelected(option) {
            // Multi-selection:
            if (this.isMultiSelect) {
                if (this.isOptionSelected(option)) {
                    this.internalSelectedOptions = this.internalSelectedOptions.filter(
                        o => o[this.optionValueKey] !== option[this.optionValueKey]
                    );
                } else {
                    this.internalSelectedOptions.push(option);
                }
            } else {
                // Single-selection:
                if (this.isOptionSelected(option)) {
                    this.internalSelectedOptions = [];
                } else {
                    this.internalSelectedOptions = [option];
                }
            }
            this.$emit('onChange', this.internalSelectedOptions);
        },
        toggleMenu() {
            this.showMenu = !this.showMenu;
            if (this.showMenu) {
                this.addWindowClickListener();
            } else {
                this.removeWindowClickListener();
            }
        },
        onSearch() {
            this.searchTextDebounced = this.searchText;
            if (this.enableExternalSearch) {
                this.$emit('search', this.searchTextDebounced);
            }
        },
        onNextPage() {
            this.$emit('nextPage');
        },
        onWindowClick(event) {
            const isClickInside = this.$el.contains(event.target);
            if (!isClickInside) {
                this.toggleMenu();
            }
        },
        addWindowClickListener() {
            window.addEventListener('click', this.onWindowClick);
        },
        removeWindowClickListener() {
            window.removeEventListener('click', this.onWindowClick);
        }
    },
    watch: {
        selectedOptions(newValue) {
            if (Array.isArray(newValue)) {
                this.internalSelectedOptions = newValue;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.grouped-dropdown-container {
    .input-container {
        width: 230px;
        position: relative;
        align-items: center;
        margin-right: 2rem;

        .arrow {
            transform: rotate(-90deg);

            &-reversed {
                transform: rotate(90deg);
            }
        }
    }

    .input-group {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: $white-blue;
        padding: 0.25rem 1rem;
        border-radius: 5px;
        border: 0;
        min-height: 32px;
        width: 100%;
        color: $base-font-color;
        font-size: 12px;
        letter-spacing: 1px;

        &.cursor-pointer {
            cursor: pointer;
        }

        .input-label {
            cursor: pointer;
            background-color: inherit;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 20rem;

            &.faded {
                color: $input-gray;
            }
        }

        &:focus {
            outline: 2px solid $edsights-blue;
        }

        > input {
            width: 100%;
            border: none;
            outline: none;
            background-color: $white-blue;
            padding: 0.25rem 3rem 0.25rem 1rem;
            border-radius: 5px;
            font: inherit;
            color: $input-gray;

            &:focus {
                outline: 2px solid $edsights-blue;
            }
        }

        .icon-search {
            position: absolute;
            right: 12px;
            height: 15px;
            width: 15px;
            margin-right: 0;
        }

        &.search-bar {
            padding: 0;
            background: transparent;
            border-radius: 5px;
            position: relative;
        }

        &.bordered {
            border: 1px solid $silver;
        }
    }

    .transition-container {
        position: absolute;
        z-index: 1000;
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.15);
        background-color: $white;
        left: 0;
        right: 0;
        max-height: 400px;
    }

    .dropdown {
        margin: 1rem;
    }

    .group-container {
        max-height: 200px;
        overflow-y: scroll;
        margin: 2rem 0;

        &:focus {
            outline: 2px solid $edsights-blue;
        }
    }

    .group {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        border: 0;
        width: 100%;
        font-size: 12px;
        letter-spacing: 1px;
        text-align: left;
        margin: 0 0 2px 0;
        line-height: 26px;
        padding: 6px 12px 6px 6px;

        &:focus {
            box-shadow: inset 0 0 0 2px $edsights-blue;
            outline: none;
        }
    }

    .option-container {
        margin-left: 1rem;
    }

    .option {
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-top: 0.5rem;
    }

    .option-checkbox {
        margin-right: 0.5rem;

        &:focus {
            outline: 2px solid $edsights-blue;
        }
    }
}
</style>
