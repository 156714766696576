<template>
    <div class="admin">
        <div @click.stop class="admin__checkbox">
            <BaseCheckbox
                isMedium
                ref="checkbox"
                :modelValue="selected"
                @update:modelValue="toggleSelected"
                :ariaLabel="`Select ` + admin.firstName + ' ' + admin.lastName"
            />
        </div>

        <div v-if="!editingAdmin" class="admin__name clickable" @dblclick="toggleEditAdmin">
            {{ nameToDisplay }}
            <LoadingSpinner v-if="sendingInvite" />
            <Button
                v-if="isNameless && !sendingInvite"
                class="resend-invite-button"
                styleType="text"
                @click="resendInvite"
            >
                Resend Invite
            </Button>
        </div>
        <div v-if="isStaff && editingAdmin" class="admin__name">
            <input type="text" name="firstName" v-model="firstName" />
            <input type="text" name="lastName" v-model="lastName" />
        </div>

        <div v-if="!editingAdmin" class="admin__email clickable" @dblclick="toggleEditAdmin">
            {{ admin.email }}
        </div>
        <div v-if="isStaff && editingAdmin" class="admin__email">
            <input type="text" name="email" v-model="email" />
        </div>

        <div class="admin__permission">
            <v-select
                :options="[this.unlimited, this.limited]"
                :modelValue="adminDisplayValue"
                @update:modelValue="updateAdminType"
                :clearable="false"
                v-if="editingAdminType"
            />
            <div class="type-display" v-else>
                {{ adminDisplayValue }}
                <button class="type-display__edit" @click="onClickEditAdminType">EDIT</button>
            </div>
        </div>

        <div class="admin__kb">
            <div v-if="editingAdminType">
                <BaseCheckbox
                    class="tag-checkbox"
                    v-model="admin.adminAccount.canAccessKb"
                    :disabled="this.checkboxDisabled"
                    :id="checkboxLabelId"
                />
                <label v-if="isExperimentalAccessibilityEnabled" :for="checkboxLabelId"
                    >Grant {{ firstName + ' ' + lastName }} Access</label
                >
            </div>

            <div class="type-display" v-else>
                {{
                    admin.adminAccount.canAccessKb || admin.adminAccount.type === MASTER_ADMIN
                        ? 'Yes'
                        : 'No'
                }}
            </div>
        </div>

        <div class="admin__action">
            <div v-if="!editingAdminType && !editingAdmin">
                <Button
                    v-if="admin.adminAccount.type !== MASTER_ADMIN"
                    class="admin__btn"
                    styleType="off"
                    unbordered
                    @click="onClickEditTags"
                >
                    Edit Tags
                </Button>
                <Button
                    v-if="isStaff"
                    class="admin__btn update"
                    styleType="off"
                    unbordered
                    @click="onClickEditPassword"
                >
                    Reset Password
                </Button>
            </div>
            <div v-if="isStaff && editingAdmin">
                <Button class="button" @click="updateAdmin">
                    Save
                </Button>
                <Button class="button button_off" @click="toggleEditAdmin">
                    Cancel
                </Button>
            </div>
            <div v-if="editingAdminType">
                <Button class="button" @click="updateAdminAccountValues">
                    Save
                </Button>
                <Button class="button button_off" @click="resetAdminAccountValues">
                    Cancel
                </Button>
            </div>
        </div>
    </div>
</template>

<script>
import { nextTick } from 'vue';

import Button from '@/components-deprecated/Button';
import { BaseCheckbox } from '@edsights/ui-core';
import Admin from '@/services/admins';
import { UNEXPECTED_NON_ERROR_RESPONSE } from '@/consts/errors';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import { updateAdminDetails, updateAdminType } from '@/api/admin';
import { isExperimentalAccessibilityEnabled } from '@/lib/feature-flag';
import { v4 } from 'uuid';

export default {
    name: 'AdminListItem',
    components: {
        LoadingSpinner,
        Button,
        BaseCheckbox
    },
    data() {
        return {
            editingAdminType: false,
            editingAdmin: false,
            loading: false,
            firstName: this.admin.firstName,
            lastName: this.admin.lastName,
            email: this.admin.email,
            MASTER_ADMIN: Admin.options.MASTER_ADMIN,
            adminInitialKbAccess: false,
            adminInitialAdminAccountType: '',
            unlimited: 'UNLIMITED',
            master: 'MASTER',
            limited: 'LIMITED',
            checkboxDisabled: false,
            checkboxLabelId: `checkbox-label-${v4()}`,
            sendingInvite: false
        };
    },
    props: {
        admin: {
            type: Admin,
            required: true
        },
        selected: {
            type: Boolean,
            default: false
        },
        isStaff: {
            type: Boolean,
            default: false
        }
    },
    created() {
        // capture these values in case user wants to ignore changes
        // note that these are primitive values
        this.adminInitialKbAccess = (this.admin.adminAccount || {}).canAccessKb;
        this.adminInitialAdminAccountType = (this.admin.adminAccount || {}).type;
    },
    computed: {
        nameToDisplay() {
            return this.admin.displayName || `N/A`;
        },
        isNameless() {
            // admins without a name have been invited but haven't completed setting up their account
            return this.admin.firstName === '';
        },
        adminDisplayValue() {
            if (this.admin.adminAccount.type === this.master) {
                return this.unlimited;
            } else {
                return this.admin.adminAccount.type;
            }
        },
        isExperimentalAccessibilityEnabled() {
            if (this.$store.state.user.schoolRef) {
                return isExperimentalAccessibilityEnabled(this.$store.state.user.schoolRef.id);
            }

            return false;
        }
    },
    methods: {
        onClickEditTags() {
            this.$emit('editTags', this.admin);
        },
        onClickEditPassword() {
            this.$emit('editPassword', this.admin);

            nextTick(() => {
                const editPasswordModal = document.querySelector('.vm--container');
                if (editPasswordModal) {
                    editPasswordModal.focus();
                }
            });
        },
        toggleEditAdmin() {
            if (this.isStaff) {
                this.editingAdmin = !this.editingAdmin;
            }
        },
        updateAdmin() {
            this.loading = true;
            updateAdminDetails({
                id: this.admin.id,
                email: this.email,
                firstName: this.firstName,
                lastName: this.lastName
            }).then(() => {
                // update UI without refetching entire list
                this.admin.email = this.email;
                this.admin.firstName = this.firstName;
                this.admin.lastName = this.lastName;
                this.admin.displayName = `${this.admin.firstName} ${this.admin.lastName}`;
                this.loading = false;
                this.editingAdmin = false;
            });
        },
        async updateAdminType(value) {
            if (value === this.unlimited) {
                this.admin.adminAccount.canAccessKb = true;
                this.admin.adminAccount.type = this.master;
                this.checkboxDisabled = true;
            } else {
                this.admin.adminAccount.canAccessKb = this.adminInitialKbAccess;
                this.admin.adminAccount.type = value;
                this.checkboxDisabled = false;
            }
        },
        onClickEditAdminType() {
            this.editingAdminType = !this.editingAdminType;
            if (this.editingAdminType && this.admin.adminAccount.type === this.master) {
                this.admin.adminAccount.canAccessKb = true;
                this.checkboxDisabled = true;
            }
        },
        toggleSelected(value) {
            if (value) {
                this.$emit('select', this.admin);
            } else {
                this.$emit('unselect', this.admin);
            }
        },
        resetAdminAccountValues() {
            this.admin.adminAccount.type = this.adminInitialAdminAccountType;
            this.admin.adminAccount.canAccessKb = this.adminInitialKbAccess;
            this.editingAdminType = false;
            this.checkboxDisabled = false;
        },
        initialValuesHaveChanged() {
            return (
                this.admin.adminAccount.type !== this.adminInitialAdminAccountType ||
                this.admin.adminAccount.canAccessKb !== this.adminInitialKbAccess
            );
        },
        async updateAdminAccountValues() {
            try {
                // only try and update if values have actually been changed
                if (this.initialValuesHaveChanged()) {
                    const updatedAdmin = await updateAdminType({
                        id: this.admin.id,
                        type: this.admin.adminAccount.type,
                        canAccessKb: this.admin.adminAccount.canAccessKb
                    });
                    if (!updatedAdmin.adminAccount) {
                        const invalidResponse = new Error();
                        invalidResponse.code = UNEXPECTED_NON_ERROR_RESPONSE;
                        throw invalidResponse;
                    } else {
                        this.adminInitialAdminAccountType = updatedAdmin.adminAccount.type;
                        this.adminInitialKbAccess = updatedAdmin.adminAccount.canAccessKb;
                    }
                }
            } catch (error) {
                this.resetAdminAccountValues();
                if (error.code && error.code.name) {
                    this.$Alert.alert({
                        type: error.code.level || 'warning',
                        message: error.code.message || error.code.name,
                        timeout: 6000
                    });
                }
            } finally {
                this.editingAdminType = false;
            }
        },
        async resendInvite() {
            const email = this.admin.email;
            try {
                this.sendingInvite = true;
                await Admin.api.reInviteCoadmin(email);
                this.sendingInvite = false;
                this.$Alert.alert({
                    type: 'success',
                    message: `<h2>Resent invite</h2><br /><p>Sent to ${email}</p>`,
                    timeout: 6000
                });
            } catch (error) {
                this.sendingInvite = false;
                this.$Alert.alert({
                    type: 'error',
                    message:
                        "<h2>Problem sending invite</h2><br /><p>Please try again or get in touch with your school's EdSights contact.</p>",
                    timeout: 6000
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';

.admin {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;

    & > * {
        width: 15%;
    }

    &__checkbox {
        width: 25px;
    }

    &__name {
        width: 20%;
    }

    &__email {
        width: 20%;
    }

    &__permission {
        width: 12%;
    }

    &__kb {
        width: 8%;
        display: flex;
        justify-content: center;
    }

    &__action {
        width: 18%;
        display: flex;
        justify-content: center;
    }

    .v--modal-overlay {
        width: 100%;
    }
}

.loader-container {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: auto;
}

.type-display {
    display: flex;

    &__edit {
        color: $primary-brand-color;
        margin-left: 1rem;
        cursor: pointer;
        background: none;
        border: 0;
        font-weight: bold;

        &:focus {
            outline: 2px solid $edsights-blue;
        }
    }
}

.clickable {
    cursor: pointer;
}

.admin__name {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    .resend-invite-button {
        font-size: 11px;
        margin-left: 1rem !important;
        font-weight: bold;
    }

    .spinner {
        display: flex;
        align-items: center;
        margin: 0 0 0 1rem;
        height: 38px;
    }

    input {
        padding: 10px 10px;
        margin-left: 0;
        margin-right: 2px;
        width: 30%;
        min-width: 12rem;
    }
}

.admin__email {
    input {
        padding: 10px 10px;
        min-width: 12rem;
    }
}

.button.button.button_off {
    margin-left: 5px;
    padding: 0 12px;

    &:focus {
        outline: 2px solid $edsights-blue;
    }
}

.update {
    color: $primary-brand-color;
}
</style>
